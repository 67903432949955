
/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------*/

/** Black */
@font-face {
    font-family: SFProDisplay;
    font-weight: 900;
    src: url("https://sf.abarba.me/SF-UI-Display-Black.otf");
}

/** Bold */
@font-face {
    font-family: SFProDisplay;
    font-weight: 700;
    src: url("https://sf.abarba.me/SF-UI-Display-Bold.otf");
}

/** Heavy */
@font-face {
    font-family: SFProDisplay;
    font-weight: 800;
    src: url("https://sf.abarba.me/SF-UI-Display-Heavy.otf");
}

/** Light */
@font-face {
    font-family: SFProDisplay;
    font-weight: 200;
    src: url("https://sf.abarba.me/SF-UI-Display-Light.otf");
}

/** Medium */
@font-face {
    font-family: SFProDisplay;
    font-weight: 500;
    src: url("https://sf.abarba.me/SF-UI-Display-Medium.otf");
}

/** Regular */
@font-face {
    font-family: SFProDisplay;
    font-weight: 400;
    src: url("https://sf.abarba.me/SF-UI-Display-Regular.otf");
}

/** Semibold */
@font-face {
    font-family: SFProDisplay;
    font-weight: 600;
    src: url("https://sf.abarba.me/SF-UI-Display-Semibold.otf");
}

/** Thin */
@font-face {
    font-family: SFProDisplay;
    font-weight: 300;
    src: url("https://sf.abarba.me/SF-UI-Display-Thin.otf");
}

/** Ultralight */
@font-face {
    font-family: SFProDisplay;
    font-weight: 100;
    src: url("https://sf.abarba.me/SF-UI-Display-Ultralight.otf");
}

/** Bold */
@font-face {
    font-family: SFProText;
    font-weight: 700;
    src: url("./SF-Pro-Text-Bold.otf");
}

/** Bold Italic */
@font-face {
    font-family: SFProText;
    font-weight: 700;
    font-style: italic;
    src: url("./SF-Pro-Text-BoldItalic.otf");
}

/** Heavy */
@font-face {
    font-family: SFProText;
    font-weight: 800;
    src: url("./SF-Pro-Text-Heavy.otf");
}

/** Heavy Italic */
@font-face {
    font-family: SFProText;
    font-weight: 800;
    font-style: italic;
    src: url("./SF-Pro-Text-HeavyItalic.otf");
}

/** Light */
@font-face {
    font-family: SFProText;
    font-weight: 200;
    src: url("./SF-Pro-Text-Light.otf");
}

/** Light Italic */
@font-face {
    font-family: SFProText;
    font-weight: 200;
    font-style: italic;
    src: url("./SF-Pro-Text-HeavyItalic.otf");
}

/** Medium */
@font-face {
    font-family: SFProText;
    font-weight: 500;
    src: url("./SF-Pro-Text-Medium.otf");
}

/** Medium Italic */
@font-face {
    font-family: SFProText;
    font-weight: 500;
    font-style: italic;
    src: url("./SF-Pro-Text-MediumItalic.otf");
}

/** Regular */
@font-face {
    font-family: SFProText;
    font-weight: 400;
    src: url("./SF-Pro-Text-Regular.otf");
}

/** Regular Italic */
@font-face {
    font-family: SFProText;
    font-weight: 400;
    font-style: italic;
    src: url("./SF-Pro-Text-RegularItalic.otf");
}

/** Semibold */
@font-face {
    font-family: SFProText;
    font-weight: 600;
    src: url("./SF-Pro-Text-Semibold.otf");
}

/** Semibold Italic */
@font-face {
    font-family: SFProText;
    font-weight: 600;
    font-style: italic;
    src: url("./SF-Pro-Text-SemiboldItalic.otf");
}
