.institutions-skeleton {
  width: 40px;
  float: right;

  .ant-skeleton-header{
    padding-right: 0;
  }

  .ant-skeleton-content {
    display: none;
    vertical-align: middle;
  }
}

.institution-selected {
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  color: #343f5c;
  max-width: 310px;
  float: right;

  .icon-arrow-down,
  .icon-arrow-up {
    vertical-align: middle;
    margin-left: 4px;
  }
}

@media (min-width: 1200px) {
  .institution-selected {
    display: flex;

  }

  .institution-user {
    display: flex;
  }

  .institutions-skeleton {
    width: 250px;

    .ant-skeleton-header{
      padding-right: 16px;
    }

    .ant-skeleton-content {
      display: table-cell;
    }
  }

}

.institution-user {
  line-height: normal;
  align-items: center;

  span {
    font-size: 15px;
    font-weight: 600;
    color: #343f5c;
  }

  small {
    font-size: 14px;
    color: #7c8595;
  }
}

.institution-logo {
  width: 38px;
  height: 38px;
  line-height: 34px;
  border-radius: 10px;
  border: 2px solid #e0e3ea;
}

.dropdown-institution-menu {
  border-radius: 4px;
  box-shadow: 0 4px 19px 1px rgba(0, 0, 0, 0.14);
  background-color: #ffffff;

  .ant-list-item-meta {
    opacity: .7;

    .ant-list-item-meta-title {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.47;
      color: #343f5c;
    }

    .ant-list-item-meta-title.logout a {
      margin: 4px 0;
      font-weight: 500;
      color: #c1456b;
    }

    .ant-list-item-meta-title.user {
      font-weight: 500;
    }

    .ant-list-item-meta-description {
      font-size: 14px;
      font-weight: 500;
      color: #7c8595;
    }
  }

  .ant-dropdown-menu-item-selected {
    background-color: #ffffff;

    .ant-list-item-meta {
      opacity: 1;
    }

    .institution-logo {
      border-color: #1bbfbb;
    }
  }
}