@charset "UTF-8";
@import url(assets/fonts/SFProText/sfprotext.css);
@import url(assets/css/animate.css);
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@font-face {
  font-family: 'custom-icons';
  src: url("assets/fonts/icons/custom-icons.ttf?gjbve9") format("truetype"), url("assets/fonts/icons/custom-icons.woff?gjbve9") format("woff"), url("assets/fonts/icons/custom-icons.svg?gjbve9#custom-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'custom-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-search:before {
  content: "\e908"; }

.icon-close:before {
  content: "\e906"; }

.icon-checked:before {
  content: "\e907"; }

.icon-arrow-down:before {
  content: "\e900"; }

.icon-arrow-up:before {
  content: "\e905"; }

.icon-arrow-right:before {
  content: "\e904"; }

.icon-arrow-left:before {
  content: "\e901"; }

.icon-dashboard:before {
  content: "\e902"; }

.icon-more:before {
  content: "\e903"; }

.ant-dropdown,
.ant-form-item,
.ant-select-dropdown,
button,
body {
  font-family: SFProText, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.4px;
  font-style: normal;
  font-stretch: normal; }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.ant-layout {
  background-color: #f9fafb; }

.ant-layout-header {
  background: white;
  padding: 0 20px;
  border: 1px solid #e8e8e8; }

.main-wrapper {
  padding: 40px 0 0; }

.item {
  color: white;
  cursor: pointer;
  height: 200px;
  margin: 20px;
  position: absolute;
  /* Required by Muuri */
  width: 50%; }

.muuri-item-dragging {
  z-index: 3;
  /* Required by Muuri */ }

.muuri-item-releasing {
  z-index: 2;
  /* Required by Muuri */ }

.muuri-item-hidden {
  z-index: 0;
  /* Required by Muuri */ }

.box1 {
  background-color: orange;
  /* Go */ }

.box2 {
  background-color: blue;
  /* Gators */ }

/* Carousel */
.slick-dots li button:before {
  font-size: 8px !important; }

.slick-prev:before, .slick-next:before {
  font-size: 40px !important;
  font-weight: 800 !important;
  opacity: 1 !important; }

.slick-prev:hover, .slick-next:hover {
  opacity: 0.7 !important; }

.slick-prev {
  left: -45px !important; }

/* MENU */
.sider {
  position: fixed;
  z-index: 1023;
  min-height: 100vh;
  background: #fff;
  padding: 64px 0 0 0; }
  .sider .menu {
    height: 100%;
    border-right: 0; }

.ant-layout-sider-zero-width-trigger,
.ant-layout-sider-zero-width-trigger:hover {
  background-color: #1bbfbb; }

.ant-menu {
  font-family: SFProText, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", serif; }

.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
  color: #343f5c; }

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item {
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  color: #7c8595;
  height: 46px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 4px;
  padding-top: 4px; }

.ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after {
  border-right: 0; }

.ant-menu-vertical .ant-menu-item:before, .ant-menu-vertical-left .ant-menu-item:before, .ant-menu-vertical-right .ant-menu-item:before, .ant-menu-inline .ant-menu-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 10px solid #1BBFBB;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;
  font-weight: bold;
  color: #343f5c; }

.ant-menu-inline .ant-menu-selected:before,
.ant-menu-inline .ant-menu-item-selected:before, .ant-menu-inline .ant-menu-selected:before,
.ant-menu-inline .ant-menu-item-selected:before {
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1); }

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  color: #1BBFBB !important; }

.ant-menu-item-selected {
  color: #343F5C !important;
  font-weight: 500; }

/* SPIN*/
.ant-spin-dot i {
  background-color: #1BBFBB !important; }

.ant-spin-lg .ant-spin-dot {
  font-size: 42px !important;
  width: 42px !important;
  height: 42px !important; }

/* SELECT */
.ant-select.select-drop > .ant-select-selection {
  border: none !important; }

/* DROPDOW */
:root .ant-dropdown-trigger > .anticon:not(.anticon-ellipsis), :root .ant-dropdown-link > .anticon:not(.anticon-ellipsis) {
  font-size: 16px !important; }

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #e6fff7; }

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-item-disabled:hover {
  background-color: #ffffff;
  cursor: default; }

/* BUTTON*/
.ant-btn-primary.btn-custom-primary {
  background-color: #1BBFBB !important;
  border-color: #1BBFBB !important; }

.ant-btn i {
  font-size: 16px; }

a.ant-btn {
  line-height: normal; }

.card-indicator {
  text-align: center;
  border-radius: 8px;
  min-height: 200px; }

.btn-details-all {
  border-color: #1BBFBB;
  color: #1BBFBB; }

.card-sub-title {
  font-weight: 500;
  text-align: center;
  background: white; }

.card-number h2 {
  font-size: 48px;
  color: #1BBFBB !important;
  margin-top: 5px; }

::selection {
  background: #1BBFBB !important; }

.map-date {
  width: 231px;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #343f5c;
  cursor: pointer;
  float: right; }
  .map-date .icon-arrow-left,
  .map-date .icon-arrow-right {
    letter-spacing: initial;
    padding: 20px;
    font-size: 22px;
    color: #7c8595; }
  .map-date .icon-arrow-left {
    float: left; }
  .map-date .icon-arrow-right {
    float: right; }
  .map-date span {
    padding: 20px 0; }

.ant-layout-footer {
  background: #f9fafb; }

.main {
  padding: 64px 20px 20px 241px;
  background: #f9fafb; }
  .main h1.title {
    font-size: 28px;
    font-weight: bold;
    color: #343f5c; }
  .main .icon-more {
    font-size: 18px;
    color: #1ab5b5; }

.hidden {
  visibility: hidden;
  display: none; }

.ant-list {
  font-family: inherit;
  font-variant: inherit; }

.ant-list-item-meta-title > a {
  color: initial; }

.ant-list-split .ant-list-item,
.ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #e0e3ea; }

.scheduling-list .ant-list-item-meta {
  flex: 6 1; }

.scheduling-list .ant-list-item-meta-avatar {
  font-family: SFProDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #1bbfbb;
  align-self: center; }

.scheduling-list .ant-list-item-meta-title {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.29;
  margin-bottom: 0;
  color: #343f5c; }

.scheduling-list .ant-list-item-meta-description {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #7c8595; }
  .scheduling-list .ant-list-item-meta-description .laterality {
    border-radius: 8px;
    background-color: #e0e3ea;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.1px;
    color: #7c8595;
    display: inline-block;
    width: 25px;
    height: 16px;
    text-align: center; }
  .scheduling-list .ant-list-item-meta-description .allergic:before,
  .scheduling-list .ant-list-item-meta-description .iot-hard:before {
    margin-left: 10px;
    margin-right: 3px;
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    align-self: center; }
  .scheduling-list .ant-list-item-meta-description .allergic:before {
    background-color: #f5c123; }
  .scheduling-list .ant-list-item-meta-description .iot-hard:before {
    background-color: #f52358; }

.scheduling-list .ant-list-item {
  padding: 16px 0 18px;
  cursor: pointer; }
  .scheduling-list .ant-list-item .scheduling-anesthetist-avatar {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: #e0e3ea;
    display: flex;
    align-items: center; }
    .scheduling-list .ant-list-item .scheduling-anesthetist-avatar span {
      font-size: 13px;
      font-weight: bold;
      color: #7c8595;
      margin: auto; }

.another-modules {
  position: fixed;
  text-align: center;
  bottom: 0;
  width: 205px;
  margin-bottom: 24px; }
  .another-modules a {
    border-color: transparent;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1bbfbb;
    width: 168px;
    height: 47px;
    border-radius: 6px;
    background-color: #f2f4f7;
    padding: 11px 11px 13px;
    box-shadow: none; }
  .another-modules a:hover,
  .another-modules a:active,
  .another-modules a:focus {
    border: 1px solid #1bbfbb;
    color: #1bbfbb;
    background-color: #fff; }
  .another-modules img {
    width: 145px; }

.ant-btn:not(.ant-btn-circle),
.ant-btn-primary:not(.ant-btn-circle),
.ant-btn-secondary:not(.ant-btn-circle) {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: normal;
  padding: 6px 23px;
  border-radius: 15px; }

.ant-btn.ant-btn-sm:not(.ant-btn-circle),
.ant-btn-primary.ant-btn-sm:not(.ant-btn-circle),
.ant-btn-secondary.ant-btn-sm:not(.ant-btn-circle) {
  padding: 3px 11px; }

.ant-btn-primary {
  color: #ffffff;
  background-color: #1bbfbb; }

.ant-btn-secondary {
  border-color: #ddf6f6;
  color: #1bbfbb;
  background-color: #ddf6f6; }

.add-procedure {
  float: right;
  border: none;
  height: 30px;
  border-radius: 15px;
  background-color: #1bbfbb;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
  padding: 7px 24px;
  margin-top: 4px; }

.add-procedure.ant-btn-circle:not(.ant-btn-lg) {
  padding: 7px 0;
  width: 30px; }

.add-procedure.ant-btn-lg {
  padding: 9px 0;
  font-size: 18px; }

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.add-procedure:hover,
.add-procedure:focus,
.add-procedure:active {
  background-color: #1bc9c5;
  border-color: #1bc9c5;
  color: #ffffff; }

.ant-divider {
  background-color: #e0e3ea; }

.ant-list-item-meta-content {
  align-self: center; }

/* FORM */
.ant-form-item-label {
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2; }
  .ant-form-item-label label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #7c8595; }

.ant-input,
.ant-time-picker-input,
.ant-select-auto-complete.ant-select .ant-input,
.ant-cascader-picker,
.ant-select .ant-select-selection {
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid #e0e3ea; }

.ant-cascader-picker .ant-input {
  border: 0; }

.ant-radio-button-wrapper {
  border: 1px solid #e0e3ea;
  border-left-width: 0; }

.ant-radio-button-wrapper:first-child {
  border-radius: 6px 0 0 6px;
  border-left: 1px solid #e0e3ea; }

.ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0; }

.ant-radio-button-wrapper-checked {
  background: #fff;
  border-color: #1bbfbb;
  color: #1bbfbb;
  box-shadow: -1px 0 0 0 #1bbfbb;
  z-index: 1; }

.ant-radio-button-wrapper-checked:first-child {
  border-color: #1bbfbb;
  box-shadow: none !important; }

.ant-form-item-required:before {
  display: none; }

.ant-form-item-no-colon .ant-form-item-label label.ant-form-item-required:after {
  display: inline-block;
  margin-right: 4px;
  content: '(OBRIGATÓRIO)';
  line-height: 1;
  font-size: 12px;
  color: #7c8595; }

.ant-form-item {
  margin-bottom: 27px; }

.ant-form-item-with-help {
  margin-bottom: 8px; }

.form-add-link {
  border: 0;
  color: #1bbfbb;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 17px;
  font-weight: 500;
  line-height: 0.82;
  letter-spacing: -0.1px; }

.form-add-link:hover {
  color: #3dccc5; }

.ant-form-item-control {
  line-height: 42.9999px; }

.autocomplete-patient {
  border: 1px solid #e0e3ea;
  border-radius: 4px;
  background-color: #ffffff; }
  .autocomplete-patient li.ant-select-dropdown-menu-item {
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.82;
    letter-spacing: -0.1px;
    color: #343f5c;
    margin: 16px 3px; }
  .autocomplete-patient li.ant-select-dropdown-menu-item:first-child {
    margin-top: 20px; }
  .autocomplete-patient .ant-select-dropdown-menu-item-group-title {
    display: none; }
  .autocomplete-patient .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #e0e3ea;
    margin: 0 17px; }
  .autocomplete-patient .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding: 0px; }
  .autocomplete-patient .ant-select-dropdown-menu-item:hover {
    background-color: #ffffff; }

.ant-modal-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: -0.4px;
  color: #343f5c; }

@keyframes slidein {
  from {
    min-height: 0;
    height: 0;
    font-size: 0; }
  to {
    min-height: 54px;
    height: 54px;
    font-size: 14px; } }

.form-list-item.slide-in {
  animation-duration: 0.25s;
  animation-name: slidein; }

.ant-checkbox-wrapper {
  display: flex;
  align-items: center; }
  .ant-checkbox-wrapper .ant-checkbox + span {
    line-height: normal; }

.form-list .form-list-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #7c8595; }

.form-list .form-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 54px;
  border-bottom: 1px solid #e0e3ea; }
  .form-list .form-list-item .laterality {
    margin-right: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #7c8595;
    float: left; }
  .form-list .form-list-item .form-list-description {
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 17px;
    font-weight: 500;
    color: #343f5c;
    float: left;
    flex-grow: 2; }
    .form-list .form-list-item .form-list-description .ant-checkbox-wrapper {
      flex-grow: 2; }
  .form-list .form-list-item .form-list-del {
    cursor: pointer;
    float: right;
    padding-right: 7px; }

/* MODAL */
.ant-modal-close {
  left: 0;
  right: initial; }

.ant-modal-title {
  text-align: center; }

.btn-ok-modal {
  right: 24px;
  position: absolute;
  top: 21px; }
  .btn-ok-modal button {
    float: right; }

.ant-modal-header {
  border: 0;
  padding: 25px 24px; }

.ant-modal-content,
.ant-modal-header {
  border-radius: 18px;
  background-color: #ffffff; }

.ant-modal-close {
  display: none; }

button.modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  font-weight: 700;
  text-decoration: none;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding-top: 20px;
  padding-left: 22px;
  display: block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 50px;
  height: 52px;
  font-size: 18px; }

/* CHECKBOX */
.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 50%; }

.ant-checkbox + span {
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.82;
  letter-spacing: -0.1px;
  color: #343f5c;
  vertical-align: middle; }

.ant-checkbox-checked .ant-checkbox-inner:after {
  font-family: 'custom-icons' !important;
  content: "\e907";
  color: #ffffff;
  top: 5px;
  left: 3px;
  font-size: 12px;
  border: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* MODAL SCHEDULING VIEW */
.modal-scheduling-view {
  margin-bottom: 29px; }
  .modal-scheduling-view .modal-scheduling-view-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: -0.4px;
    color: #343f5c; }
  .modal-scheduling-view .modal-scheduling-view-item {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: -0.4px;
    color: #343f5c; }
    .modal-scheduling-view .modal-scheduling-view-item .modal-scheduling-view-item-title + span {
      display: flex;
      align-items: center; }
    .modal-scheduling-view .modal-scheduling-view-item .modal-scheduling-view-item-title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.1px;
      color: #7c8595; }
  .modal-scheduling-view .modal-scheduling-view-item:nth-child(2) {
    margin-top: 7px; }
  .modal-scheduling-view .modal-scheduling-view-item {
    margin-top: 17px; }

.passwordBox {
  max-width: 460px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px; }

#root {
  height: 100%; }

.login-form > .ant-card > .ant-card-body {
  padding: 5px 24px !important; }

.login-page .ant-card-head {
  border: 0; }

.login-page .logo-axreg {
  width: 100%;
  max-width: 302px; }

.login-page .ant-card {
  border-radius: 8px;
  box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04); }

.login-page .ant-card-head-title {
  font-size: 17px;
  letter-spacing: 0.5px;
  color: #343f5c;
  font-weight: 600; }

.modal-procedures-list .ant-list-split .ant-list-item, .modal-procedures-list .ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #f2f4f7; }

.ant-notification {
  z-index: 1050; }

.ant-avatar {
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
  background-color: #e0e3ea;
  color: #7c8595; }

@media only screen and (max-width: 575px) {
  /* For mobile phones: */
  .map-date {
    width: 195px; }
    .map-date .icon-arrow-left,
    .map-date .icon-arrow-right {
      padding: 20px 10px; } }

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1024; }
